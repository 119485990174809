<template>
  <header>
    <nav>
      <div class="nav-content"> <!-- 新增一个包裹 Nav 内容的 div -->
        <img src="../assets/logo.png" class="logo" />
        <ul>
          <li><a href="#home">首页</a></li>
          <li><a href="#products">产品中心</a></li>
          <li><a href="#applications">应用领域</a></li>
          <li><a href="#solutions">解决方案</a></li>
          <li><a href="#about">关于我们</a></li>
          <img :src="require('@/assets/more.png')" class="more-img" />  
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'Header'
}
</script>

<style>
* {
  margin: 0; /* 重置所有元素的外边距 */
  padding: 0; /* 重置所有元素的内边距 */
  box-sizing: border-box; /* 确保边框和内边距计算在元素总宽度中 */
}

.more-img {
  margin-left: 300px; /* 左侧间距 */
  width: 18px; /* 宽度 */
  height: 18px;
}

.logo {
  margin-left: 0px;
  width: 130px; /* logo 的宽度 */
  height: auto; /* 高度自适应 */
  margin-right: 70px; /* logo 的右外边距 */
}

nav {
  width: 100%; /* 设置宽度为100% */
  height: 80px; /* 设置高度 */
  display: flex; /* 使用 Flexbox 布局 */
  justify-content: center; /* 将内容居中对齐 */
  align-items: center; /* 垂直居中对齐 */
  background:black; /* 设置背景色 */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); /* 设置阴影 */
  backdrop-filter: blur(4px); /* 设置模糊背景效果 */
}

.nav-content {
  display: flex; /* 使用 Flexbox 对齐 Logo 和菜单项 */
  align-items: center; /* 垂直居中对齐 */
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex; /* 将 ul 显示为 flexbox 以水平排列 */
}

nav li {
  margin-left: 15px; /* 左侧间距 */
}

nav li a {
  color: white; /* 文字颜色 */
  text-align: center; /* 文本水平居中 */
  padding: 15px 10px; /* 内边距 */
  text-decoration: none; /* 去掉下划线 */
}

nav li a:hover {
  color: #177DD0; /* 悬停时的颜色 */
}
</style>
