<template>
  <section class="services">
    <div class="service-item" v-for="service in services" :key="service.title">
      <img :src="service.img" alt="Service Icon" />
      <h3>{{ service.title }}</h3>
      <p>{{ service.description }}</p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Services',
  data() {
    return {
      services: [
        {
          title: '脑机设备提供商',
          description: '脑机设备研发、制造和销售，提供脑机设备的整体解决方案',
          img: require('@/assets/icon1.png')
        },
        {
          title: '脑机产品服务商',
          description: '为脑机设备研发、制造、销售、使用、维修等方面提供服务',
          img: require('@/assets/icon2.png')
        },
        {
          title: '消费级医疗器械产品提供商',
          description: '提供脑机、注意力训练等产品的消费级医疗器械产品',
          img: require('@/assets/icon3.png')
        }
      ]
    }
  }
}
</script>

<style scoped>
.services {
  justify-content: space-around;
  text-align: center; 
  background: linear-gradient(0deg, #073458 0%, #062640 100%);
  width: 100%; /* 确保容器宽度充足 */
}

.service-item {
  display: inline-block;
  text-align: center;
  width: 350px; /* 设置固定宽度 */
  padding: 50px; /* 内边距 */
  margin: 10px;
}

.service-item img {
  width: 64px;  /* 设置宽度 */
  height: 64px; /* 设置高度 */
  margin-top: 20px;
}

.service-item h3 {
  font-family: 'Source Han Sans SC', sans-serif; /* 字体 */
  font-size: 20px; /* 字体大小 */
  font-weight: 700; /* 字体粗细 */
  line-height: 28.96px; /* 行高 */
  text-align: center; /* 文本对齐 */
  color: #FFFFFF;
  margin-top: 30px;
}

.service-item p {
  font-family: 'Source Han Sans SC', sans-serif; /* 字体 */
  font-size: 14px; /* 字体大小 */
  font-weight: 400; /* 字体粗细 */
  line-height: 20.27px; /* 行高 */
  text-align: center; /* 文本对齐 */
  color: #999999;
  margin-top: 20px;
}
</style>
