var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"86px"}},[_c('h1',{staticStyle:{"text-align":"center","margin-top":"20px","font-family":"Source Han Sans SC","font-size":"32px","font-weight":"700","line-height":"46.34px"}},[_vm._v("产品介绍")]),_c('p',{staticStyle:{"text-align":"center","margin-top":"20px","font-family":"Source Han Sans SC","font-size":"16px","font-weight":"400","line-height":"23.17px"}},[_vm._v(" 以自主创新的\"电生理传感\"技术为核心，为神经科学研究新研究和临床神经疾病诊断、治疗与康复研究提供专业、完整的解决方案。 ")]),_c('div',{staticClass:"tab-buttons"},[_c('button',{staticClass:"tab-button",class:{ active: _vm.activeTab === 'EEG' },on:{"click":function($event){return _vm.changeTab('EEG')}}},[_c('img',{staticClass:"icon",attrs:{"src":_vm.activeTab === 'EEG' ? require('@/assets/EEG_icon2.png') : require('@/assets/EEG_icon1.png'),"alt":""}}),_vm._v(" EEG采集器 ")]),_c('button',{staticClass:"tab-button",class:{ active: _vm.activeTab === 'NIRS' },on:{"click":function($event){return _vm.changeTab('NIRS')}}},[_c('img',{staticClass:"icon",attrs:{"src":_vm.activeTab === 'NIRS' ? require('@/assets/fNIRS_icon2.png') : require('@/assets/fNIRS_icon1.png'),"alt":""}}),_vm._v(" fNIRS采集器 ")]),_c('button',{staticClass:"tab-button",class:{ active: _vm.activeTab === 'tEDS' },on:{"click":function($event){return _vm.changeTab('tEDS')}}},[_c('img',{staticClass:"icon",attrs:{"src":_vm.activeTab === 'tEDS' ? require('@/assets/tEDS_icon2.png') : require('@/assets/tEDS_icon1.png'),"alt":""}}),_vm._v(" tEDS电刺激 ")])]),(_vm.activeTab === 'EEG')?_c('div',{staticClass:"product-content"},[_vm._m(0),_vm._m(1)]):_vm._e(),(_vm.activeTab === 'NIRS')?_c('div',{staticClass:"product-content"},[_vm._m(2),_vm._m(3)]):_vm._e(),(_vm.activeTab === 'tEDS')?_c('div',{staticClass:"product-content"},[_vm._m(4),_vm._m(5)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-specs"},[_c('h2',{staticClass:"nirs-title"},[_vm._v("EEG采集器")]),_c('p',{staticClass:"nirs-paragraph"},[_vm._v("8-64导脑电采集器")]),_c('table',{staticClass:"excel-table"},[_c('tr',{staticClass:"table-header"},[_c('td',[_vm._v("指标")]),_c('td',[_vm._v("参数")])]),_c('tr',{staticClass:"table-row"},[_c('td',[_vm._v("采集通道数")]),_c('td',[_vm._v("64-8通道")])]),_c('tr',{staticClass:"table-row"},[_c('td',[_vm._v("采样频率")]),_c('td',[_vm._v("16000Hz")])]),_c('tr',{staticClass:"table-row"},[_c('td',[_vm._v("信号输入阻抗")]),_c('td',[_vm._v(">=1GΩ")])]),_c('tr',{staticClass:"table-row"},[_c('td',[_vm._v("输入信号共模抑制比")]),_c('td',[_vm._v(">=110dB")])]),_c('tr',{staticClass:"table-row"},[_c('td',[_vm._v("信号采样精度")]),_c('td',[_vm._v("24bit")])]),_c('tr',{staticClass:"table-row"},[_c('td',[_vm._v("输入噪声")]),_c('td',[_vm._v("<=0.4uVpp")])]),_c('tr',{staticClass:"table-row"},[_c('td',[_vm._v("输出方式")]),_c('td',[_vm._v("有线/无线传输")])]),_c('tr',{staticClass:"table-row"},[_c('td',[_vm._v("系统续航时间")]),_c('td',[_vm._v("12h")])]),_c('tr',{staticClass:"table-row"},[_c('td',[_vm._v("充电时间")]),_c('td',[_vm._v("2h")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-image"},[_c('img',{attrs:{"src":require("@/assets/EEG_device.png"),"alt":"EEG设备"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-specs"},[_c('h2',{staticClass:"nirs-title"},[_vm._v("fNIRS采集器")]),_c('p',{staticClass:"nirs-paragraph"},[_vm._v("16-16导fNIRS采集器")]),_c('table',{staticClass:"excel-table"},[_c('tr',{staticClass:"table-header"},[_c('td',[_vm._v("指标")]),_c('td',[_vm._v("参数")])]),_c('tr',{staticClass:"table-row"},[_c('td',[_vm._v("采集通道数                   ")]),_c('td',[_vm._v("2*8-32*32通道")])]),_c('tr',{staticClass:"table-row"},[_c('td',[_vm._v("采样频率")]),_c('td',[_vm._v("50Hz")])]),_c('tr',{staticClass:"table-row"},[_c('td',[_vm._v("光源功率")]),_c('td',[_vm._v("30mW")])]),_c('tr',{staticClass:"table-row"},[_c('td',[_vm._v("光敏设备")]),_c('td',[_vm._v(">雪崩二极管")])]),_c('tr',{staticClass:"table-row"},[_c('td',[_vm._v("信号采样精度")]),_c('td',[_vm._v("24bit")])]),_c('tr',{staticClass:"table-row"},[_c('td',[_vm._v("波长")]),_c('td',[_vm._v("780nm、830nm")])]),_c('tr',{staticClass:"table-row"},[_c('td',[_vm._v("输出方式")]),_c('td',[_vm._v("有线/无线传输")])]),_c('tr',{staticClass:"table-row"},[_c('td',[_vm._v("系统续航时间")]),_c('td',[_vm._v("12h")])]),_c('tr',{staticClass:"table-row"},[_c('td',[_vm._v("充电时间")]),_c('td',[_vm._v("2h")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-image"},[_c('img',{attrs:{"src":require("@/assets/NIRS_device.png"),"alt":"NIRS设备"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-specs"},[_c('h2',{staticClass:"nirs-title"},[_vm._v("tEDS电刺激")]),_c('p',{staticClass:"nirs-paragraph"},[_vm._v("8导tEDS电刺激器")]),_c('table',{staticClass:"excel-table"},[_c('tr',{staticClass:"table-header"},[_c('td',[_vm._v("指标")]),_c('td',[_vm._v("参数")])]),_c('tr',{staticClass:"table-row"},[_c('td',[_vm._v("刺激通道数")]),_c('td',[_vm._v("24通道同步刺激")])]),_c('tr',{staticClass:"table-row"},[_c('td',[_vm._v("电压范围")]),_c('td',[_vm._v("10V~60V 升压直流转换器")])]),_c('tr',{staticClass:"table-row"},[_c('td',[_vm._v("电流范围")]),_c('td',[_vm._v("33uA~67mA输出电流")])]),_c('tr',{staticClass:"table-row"},[_c('td',[_vm._v("刺激脉宽                            ")]),_c('td',[_vm._v("2uS~自定义刺激脉宽")])]),_c('tr',{staticClass:"table-row"},[_c('td',[_vm._v("刺激频率")]),_c('td',[_vm._v("0Hz~250KHz")])]),_c('tr',{staticClass:"table-row"},[_c('td',[_vm._v("波形设置")]),_c('td',[_vm._v("正弦/三角形/方形或任意波形")])]),_c('tr',{staticClass:"table-row"},[_c('td',[_vm._v("控制方式")]),_c('td',[_vm._v("无线传输")])]),_c('tr',{staticClass:"table-row"},[_c('td',[_vm._v("系统续航时间")]),_c('td',[_vm._v("12h")])]),_c('tr',{staticClass:"table-row"},[_c('td',[_vm._v("充电时间")]),_c('td',[_vm._v("2h")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-image"},[_c('img',{attrs:{"src":require("@/assets/tEDS_device.png"),"alt":"tEDS设备"}})])
}]

export { render, staticRenderFns }