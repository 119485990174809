<template>
  <div id="app">
    <Header />
    <Banner />
    <ProductIntro />
    <Services />
    <ProductScenarios />
    <Footer />
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Banner from './components/Banner.vue'
import ProductIntro from './components/ProductIntro.vue'
import Services from './components/Services.vue'
import ProductScenarios from './components/ProductScenarios.vue'
import Footer from './components/Footer.vue'

export default {
  components: {
    Header,
    Banner,
    ProductIntro,
    Services,
    ProductScenarios,
    Footer
  }
}
</script>

<style>
#app {
  margin: 0; /* 消除外边距 */
  padding: 0; /* 消除内边距 */
  border: 0; /* 消除边框 */
}
</style>
