<script setup lang="ts">
import { Descriptions } from 'element-ui';
import { ref } from 'vue'

const scenarios = ref([
  '脑控打字', '运动想象', '脑控无人机',
  '睡眠抑郁症检测',  
])

const activeScenario = ref('脑控打字')

// 定义每个场景的内容和视频
const scenarioContent = {
  '脑控打字': {
    title: '脑控打字的应用',
    description: '脑控打字技术，作为神经科学与信息技术的结晶，正引领人机交互的新潮流。该技术利用脑机接口设备，捕捉并分析大脑信号，实现仅凭思维即可驱动文字输入，彻底摆脱键盘与鼠标的限制。用户经过简单训练，系统便能识别其脑电波模式，转化为屏幕文字。这不仅为行动不便、言语障碍者带来沟通自由，更预示未来工作效率与信息处理速度的巨大提升。随着AI算法与硬件技术的不断进步，脑控打字应用正逐步成熟，有望成为连接人类智慧与数字世界的桥梁，开启人机交互新篇章。',
    videoSrc: ('../assets/videotyping.mp4'), 
  
  },
  '运动想象': {
    title: '运动想象的应用',
    description:'基于脑机接口的运动想象应用，是一项革命性的技术突破。它利用先进的脑机接口设备，捕捉用户大脑中的运动想象信号，无需实际肢体动作，即可实现对外部设备的控制。这一技术不仅为运动障碍患者提供了新的康复手段，让他们通过想象恢复运动功能，还拓展了人机交互的边界，让用户在虚拟现实、游戏等领域获得更沉浸式的体验。随着技术的不断成熟，基于脑机接口的运动想象应用有望在更多领域展现其潜力，为人们的生活带来更多便利和乐趣。',
    videoSrc: '/videos/运动想象.mp4', // 替换为实际视频路径
  },
  '脑控无人机': {
    title: '脑控无人机的应用',
    description:'脑控无人机技术，作为脑机接口领域的一项创新应用，正逐步展现其独特魅力。该技术通过捕捉并分析用户的大脑信号，实现对无人机的精准控制，无需传统遥控器。用户只需在脑海中想象飞行路径和动作，无人机便能即时响应，完成复杂任务。这种控制方式不仅提高了无人机的操作灵活性和效率，还为残障人士提供了参与无人机飞行的可能。随着技术的不断成熟，脑控无人机有望在救援、拍摄、环境监测等领域发挥重要作用，开启无人机应用的新篇章。',
    videoSrc: require('../assets/videofly.mp4'), 
  },
  '睡眠抑郁症检测': {
    title: '睡眠抑郁症检测',
    description:'基于脑机接口的睡眠抑郁症检测技术，是一种创新的医疗诊断方法。该技术通过脑机接口设备，实时监测患者在睡眠过程中的脑电波变化，进而分析其与抑郁症之间的潜在联系。通过深度学习算法，系统能够准确识别出与抑郁症相关的特征脑电波模式，为医生提供客观、精确的诊断依据。这种检测方法不仅提高了抑郁症的诊断准确率，还有助于早期发现和治疗，改善患者的生活质量。随着技术的不断进步，基于脑机接口的睡眠抑郁症检测有望成为未来抑郁症诊断的重要手段。',
    videoSrc: '/videos/心理学.mp4', // 替换为实际视频路径
  },
}
</script>

<template>
  <div class="application-scenarios">
    <h1 class="title">应用场景</h1>
    <nav class="menu">
      <ul>
        <li v-for="scenario in scenarios" :key="scenario"
            :class="{ active: scenario === activeScenario }"
            @click="activeScenario = scenario">
          {{ scenario }}
        </li>
      </ul>
    </nav>
    <div class="content">
      <div class="text-content">
        <h2>{{ scenarioContent[activeScenario].title }}</h2>
        <p>
          {{ scenarioContent[activeScenario].description }}
        </p>
        <button class="details-button">查看详情</button>
      </div>
      <div class="image-content">  <!-- 将视频放在右侧 -->
        <video controls :src="scenarioContent[activeScenario].videoSrc" width="400"> 
          该浏览器不支持视频播放。
        </video>
      </div>
    </div>
  </div>
</template>

<style scoped>
.application-scenarios {
  font-family: Arial, sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.menu {
  background-color: transparent; /* 确保背景是透明的 */
  box-shadow: none;
}

.title {
  margin-bottom: 20px;
  font-family: Source Han Sans SC;
  font-size: 32px;
  font-weight: 700;
  line-height: 46.34px;
  text-align: center;
  color:#222222;
  margin-top: 50px;
}

.menu ul {
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  margin-bottom: 30px;
}

.menu li {
  margin: 0 10px;
  padding: 5px 10px;
  cursor: pointer;
  color:#999999;
  font-family: Source Han Sans SC;
font-size: 16px;
font-weight: 400;
line-height: 23.17px;
text-align: center;

}

.menu li.active {
  border-bottom: 2px solid #177DD0;
  font-family: Source Han Sans SC;
font-size: 16px;
font-weight: 700;
line-height: 23.17px;
text-align: center;
  color: #222222;
}

.content {
  display: flex; /* 将内容设置为 flexbox */
  justify-content: space-between;
  align-items: flex-start; /* 使内容顶部对齐 */
  background-color: #f8f9fa;
  padding: 30px;
  border-radius: 8px;
}

.text-content {
  flex: 1;
  padding-right: 30px;
}

.text-content h2 {
  margin-bottom: 15px;
  font-family: Source Han Sans SC;
font-size: 20px;
font-weight: 700;
line-height: 28.96px;
text-align: left;
color: #222222;

}

.text-content p {
  line-height: 1.6;
  color: #222222;
  margin-bottom: 20px;
  font-family: Source Han Sans SC;
font-size: 14px;
font-weight: 400;
line-height: 20.27px;
text-align: left;

}

.details-button {
  padding: 8px 20px;
  color: #007bff;
  background-color: white;
  border: 1px solid #007bff;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 15px;
  font-family: Source Han Sans SC;
font-size: 16px;
font-weight: 700;
line-height: 23.17px;
text-align: left;

}

.details-button:hover {


background: #177DD0;
border-radius: 4px;
color:#FFFFFF;

}
.image-content {
  flex: 1;
  position: relative;
}

.image-content video {
  width: 100%; /* 确保视频宽度自适应 */
  border-radius: 8px;
}
</style>
