<template>
  <footer class="footer">
    <div class="footer-content">
      <div class="company-info">
        <img :src="require('@/assets/logo2.png')" alt="Company Logo" class="company-logo" />  
        <h2 class="company-name">{{ companyInfo.name }}</h2>
        <p class="company-detail">地址: {{ companyInfo.address }}</p>
        <p class="company-detail">邮编: {{ companyInfo.postalCode }}</p>
        <p class="company-detail">邮箱: {{ companyInfo.email }}</p>
      </div>
      <div class="qr-code">
        <img :src="require('@/assets/QRcode.png' )" alt="QR Code" />  
        <p>扫码添加企业微信</p>
      </div>
    </div>
    <div class="copyright">
      <div class="copyright-content">
        <p>&copy; Copyright {{ currentYear }} Guangzhou Qianga Technology Co.,Ltd. All Rights Reserved</p>
        <p class="license">
          <img :src="require('@/assets/license.png')" alt="License Icon" class="license-icon" />
           &nbsp; 粤公网安备 44011202001024号
        </p>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { ref } from 'vue'

const companyInfo = ref({
  name: '广州乾伽脑科技有限公司',
  address: '广东省广州市番禺区小谷围街道中关村青创会208',
  postalCode: '510000',
  email: 'big5hbci@gmail.com'
})

const currentYear = new Date().getFullYear()
</script>

<style scoped>
.footer {
  background-color: #444444;
  color: #fff;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.footer-content {
  text-align: center; /* 将内容居中对齐 */
  margin: 0 auto; /* 中心定位 */
  margin-top: 20px;
}

.company-info {
  display: inline-block; /* 块级元素，使其可以水平排列 */
  margin-right: 500px;
  margin-bottom: 50px;
  
}

.qr-code{
 width: 200px;
 display: inline-block;
}

.qr-code img {
  width: 176px; /* 设定适当的宽度 */
  height: auto; /* 保持纵横比 */
}

.company-logo {
  margin-bottom: 10px;
  width: 68px;
  height: 48px;
  margin-left: -280px;
}

.company-name {
  font-family: 'Source Han Sans SC', sans-serif; /* 设置字体 */
  font-size: 20px; /* 设置字体大小 */
  font-weight: 400; /* 设置字体粗细 */
  line-height: 28.96px; /* 设置行高 */
  text-align: left; /* 设置文本对齐方式 */
  margin-bottom: 25px; /* 可选：设置底部间距 */
}

.company-detail {
  font-family: 'Source Han Sans SC', sans-serif; /* 字体 */
  font-size: 14px; /* 字体大小 */
  font-weight: 400; /* 字体粗细 */
  line-height: 20.27px; /* 行高 */
  text-align: left; /* 文本对齐方式 */
  margin: 5px 0; /* 设置段落间距 */
  margin-bottom: 20px;
}

.qr-code p {
  font-size: 12px;
font-family: Source Han Sans SC;
font-size: 14px;
font-weight: 400;
line-height: 20.27px;
color: #999999;
margin-top: 15px;
}

.copyright-content {
  display: flex; /* 使用 Flexbox 布局 */
  justify-content: center; /* 水平居中对齐 */
  align-items: center; /* 垂直方向上对齐 */
  margin-left: 150px;
  margin-top: 15px;

}

.copyright-content p {
  font-family: 'Source Han Sans SC', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 17.38px;
  text-align: left; /* 左对齐文本 */
  margin: 0; /* 移除默认边距 */
  color: #999999;
}

.copyright {
  text-align: center;
  font-size: 12px;
  border-top: 1px solid #555;
}


.license {
  display: flex;
  align-items: center;
  justify-content: center;
}

.license-icon {
  width: 15px;
  height: 16px;
  margin-left: 600px;
  margin-top: px;
}


</style>
