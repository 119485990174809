<template>
  <div style="margin-top: 86px;">
    <h1 style="text-align: center; margin-top: 20px;font-family: Source Han Sans SC;
font-size: 32px;font-weight: 700;line-height: 46.34px;">产品介绍</h1>
    <p style="text-align: center; margin-top: 20px;font-family: Source Han Sans SC;
font-size: 16px;font-weight: 400;line-height: 23.17px;text-align: center;
">
      以自主创新的"电生理传感"技术为核心，为神经科学研究新研究和临床神经疾病诊断、治疗与康复研究提供专业、完整的解决方案。
    </p>

    <div class="tab-buttons">
      <button 
        class="tab-button" 
        :class="{ active: activeTab === 'EEG' }" 
        @click="changeTab('EEG')">
        <img :src="activeTab === 'EEG' ? require('@/assets/EEG_icon2.png') : require('@/assets/EEG_icon1.png')" alt="" class="icon" /> EEG采集器
      </button>
      <button 
        class="tab-button" 
        :class="{ active: activeTab === 'NIRS' }" 
        @click="changeTab('NIRS')">
        <img :src="activeTab === 'NIRS' ? require('@/assets/fNIRS_icon2.png') : require('@/assets/fNIRS_icon1.png')" alt="" class="icon" /> fNIRS采集器
      </button>
      <button 
        class="tab-button" 
        :class="{ active: activeTab === 'tEDS' }" 
        @click="changeTab('tEDS')">
        <img :src="activeTab === 'tEDS' ? require('@/assets/tEDS_icon2.png') : require('@/assets/tEDS_icon1.png')" alt="" class="icon" /> tEDS电刺激
      </button>
    </div>
    

    <div v-if="activeTab === 'EEG'" class="product-content">
      <div class="product-specs">
        <h2 class="nirs-title">EEG采集器</h2>
        <p class="nirs-paragraph">8-64导脑电采集器</p>
        <table class="excel-table">
          <tr class="table-header">
            <td>指标</td>
            <td>参数</td>
          </tr>
          <tr class="table-row"> <!-- 添加类 -->
            <td>采集通道数</td>
            <td>64-8通道</td>
          </tr>
          <tr class="table-row"> <!-- 添加类 -->
            <td>采样频率</td>
            <td>16000Hz</td>
          </tr>
          <tr class="table-row"> <!-- 添加类 -->
            <td>信号输入阻抗</td>
            <td>>=1GΩ</td>
          </tr>
          <tr class="table-row"> <!-- 添加类 -->
            <td>输入信号共模抑制比</td>
            <td>>=110dB</td>
          </tr>
          <tr class="table-row"> <!-- 添加类 -->
            <td>信号采样精度</td>
            <td>24bit</td>
          </tr>
          <tr class="table-row"> <!-- 添加类 -->
            <td>输入噪声</td>
            <td><=0.4uVpp</td>
          </tr>
          <tr class="table-row"> <!-- 添加类 -->
            <td>输出方式</td>
            <td>有线/无线传输</td>
          </tr>
          <tr class="table-row"> <!-- 添加类 -->
            <td>系统续航时间</td>
            <td>12h</td>
          </tr>
          <tr class="table-row"> <!-- 添加类 -->
            <td>充电时间</td>
            <td>2h</td>
          </tr>
        </table>
      </div>
      <div class="product-image">
        <img src="@/assets/EEG_device.png" alt="EEG设备">
      </div>
    </div>

    <div v-if="activeTab === 'NIRS'" class="product-content">
      <div class="product-specs">
        <h2 class="nirs-title">fNIRS采集器</h2>
        <p class="nirs-paragraph">16-16导fNIRS采集器</p>
        <table class="excel-table">
          <tr class="table-header">
            <td>指标</td>
            <td>参数</td>
          </tr>
          <tr class="table-row"> <!-- 添加类 -->
            <td>采集通道数&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
            <td>2*8-32*32通道</td>
          </tr>
          <tr class="table-row"> <!-- 添加类 -->
            <td>采样频率</td>
            <td>50Hz</td>
          </tr>
          <tr class="table-row"> <!-- 添加类 -->
            <td>光源功率</td>
            <td>30mW</td>
          </tr>
          <tr class="table-row"> <!-- 添加类 -->
            <td>光敏设备</td>
            <td>>雪崩二极管</td>
          </tr>
          <tr class="table-row"> <!-- 添加类 -->
            <td>信号采样精度</td>
            <td>24bit</td>
          </tr>
          <tr class="table-row"> <!-- 添加类 -->
            <td>波长</td>
            <td>780nm、830nm</td>
          </tr>
          <tr class="table-row"> <!-- 添加类 -->
            <td>输出方式</td>
            <td>有线/无线传输</td>
          </tr>
          <tr class="table-row"> <!-- 添加类 -->
            <td>系统续航时间</td>
            <td>12h</td>
          </tr>
          <tr class="table-row"> <!-- 添加类 -->
            <td>充电时间</td>
            <td>2h</td>
          </tr>
        </table>
      </div>
      <div class="product-image">
        <img src="@/assets/NIRS_device.png" alt="NIRS设备">
      </div>
    </div>

    <div v-if="activeTab === 'tEDS'" class="product-content">
      <div class="product-specs">
        <h2 class="nirs-title">tEDS电刺激</h2>
        <p class="nirs-paragraph">8导tEDS电刺激器</p>
        <table class="excel-table">
          <tr class="table-header">
            <td>指标</td>
            <td>参数</td>
          </tr>
          <tr class="table-row"> <!-- 添加类 -->
            <td>刺激通道数</td>
            <td>24通道同步刺激</td>
          </tr>
          <tr class="table-row"> <!-- 添加类 -->
            <td>电压范围</td>
            <td>10V~60V 升压直流转换器</td>
          </tr>
          <tr class="table-row"> <!-- 添加类 -->
            <td>电流范围</td>
            <td>33uA~67mA输出电流</td>
          </tr>
          <tr class="table-row"> <!-- 添加类 -->
            <td>刺激脉宽&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
            <td>2uS~自定义刺激脉宽</td>
          </tr>
          <tr class="table-row"> <!-- 添加类 -->
            <td>刺激频率</td>
            <td>0Hz~250KHz</td>
          </tr>
          <tr class="table-row"> <!-- 添加类 -->
            <td>波形设置</td>
            <td>正弦/三角形/方形或任意波形</td>
          </tr>
          <tr class="table-row"> <!-- 添加类 -->
            <td>控制方式</td>
            <td>无线传输</td>
          </tr>
          <tr class="table-row"> <!-- 添加类 -->
            <td>系统续航时间</td>
            <td>12h</td>
          </tr>
          <tr class="table-row"> <!-- 添加类 -->
            <td>充电时间</td>
            <td>2h</td>
          </tr>
        </table>
      </div>
      <div class="product-image">
        <img src="@/assets/tEDS_device.png" alt="tEDS设备">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeTab: 'EEG', // 确保初始选项卡为 EEG
      tabIndex: 0,
      tabs: ['EEG', 'NIRS', 'tEDS'],
    };
  },
  created() {
    this.startAutoSwitch();
  },
  beforeDestroy() {
    clearInterval(this.autoSwitchInterval);
  },
  methods: {
    changeTab(tabName) {
      this.activeTab = tabName;
      clearInterval(this.autoSwitchInterval); // 手动切换时清除自动切换
      this.startAutoSwitch(); // 重新开始自动切换
    },
    startAutoSwitch() {
      this.autoSwitchInterval = setInterval(() => {
        this.tabIndex = (this.tabIndex + 1) % this.tabs.length;
        this.activeTab = this.tabs[this.tabIndex];
      }, 3000); // 每3秒切换一次
    }
  }
}
</script>

<style scoped>
.icon {
  width: 16px; /* 设置图标宽度 */
  height: 13px; /* 设置图标高度 */
  margin-bottom: 5px;
  vertical-align: middle; /* 使图标与文字垂直对齐 */
}

/* 现有样式保持不变 */
body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}
h1 {
  color: #333;
}
.tab-buttons {
  margin-top: 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}
.tab-button {
  padding: 10px 20px;
  margin-right: 50px; /* 增加按钮之间的间距 */
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
  font-family: Source Han Sans SC;
  font-size: 16px;
  font-weight: 400;
  line-height: 23.17px;
  text-align: left;
  border-radius: 6px;
}
.tab-button.active {
  background-color: #007bff;
  color: white;
}
.product-content {
  display: flex;
  align-items: center;
  justify-content: center; /* 水平居中 */
  margin-bottom: 100px;
}
.product-specs {
  width: 400px; /* 固定宽度 */
  margin-right: 100px; /* 数据与图片之间的间距 */
}
.product-image {
  width: 533px; /* 固定宽度 */
  height: auto; /* 根据需要调整高度 */
  display: flex;
  align-items: center;
  justify-content: center;
}
table {
  width: 100%;
  border-collapse: collapse;
}
th, td {
  border-bottom: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}
img {
  max-width: 100%;
  height: auto; 
}

.nirs-title { /* 为 NIRS 的 h2 添加样式 */
  font-family: 'Source Han Sans SC';
  font-size: 20px;
  font-weight: 700;
  line-height: 28.96px;
  text-align: left;
  color: #222222;
  border: none;
}

.nirs-paragraph { /* 为 NIRS 的 p 添加样式 */
  font-family: 'Source Han Sans SC';
  font-size: 14px;
  font-weight: 400;
  line-height: 20.27px;
  text-align: left;
  margin-top: 10px;
  color: #999999;
  border: none;
}

.table-header { /* 为表头行添加样式 */
  font-family: 'Source Han Sans SC';
  font-size: 14px;
  font-weight: 400;
  line-height: 20.27px;
  text-align: left;
  margin-top: 20px;
  border: none; /* 去掉默认的边框 */
  border-top: 1px solid rgba(254, 254, 254, 0); /* 添加上边框，颜色为黑色 */
  padding: 0; /* 取消默认的内边距 */
  position: relative; /* 使得伪元素相对于此元素定位 */
}

.table-header::after { /* 用于创建短边框 */
  content: '';
  position: absolute;
  left: 10px; /* 控制左侧起始位置 */
  right: 360px; /* 控制右侧位置 */
  top: 0; /* 与表头位置重合 */
  border-top: 2px solid #222222; /* 短边框 */
  z-index: 1; /* 确保短边框在上层 */
}

.excel-table {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 20px;
  border: 1px solid white;
  border: none;
}

.table-row { /* 为表格的行添加样式 */
  font-family: Source Han Sans SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.27px;
  text-align: left;
  color: #666666; /* 字体颜色 */
  border: none;
  line-height: 0.5; /* 设置行高 */
  padding: 5px 0;   /* 设置上下内边距 */
}

table {
  width: 100%;
  border-collapse: collapse; /* 确保内框线效果 */
  border: none; /* 去除表格外框 */
  margin-left: -10px;
}

th, td {
  border: none; /* 去掉每个单元格的边框 */
  padding: 10px;
  text-align: left;
}

</style>
